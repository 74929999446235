


import React, { useState } from 'react';

const ConsultaComponent = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const handleConsulta = async () => {
        setLoading(true);
        setError(null); // Reiniciar error al iniciar la consulta

        try {
            const response = await fetch('https://www.je-innovate.com/backend/getData.php'); // Cambia esta URL por la de tu API
            if (!response.ok) {
                throw new Error('Error en la consulta');
            }
            const result = await response.json();
            setData(result);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <button onClick={handleConsulta}>Consultar</button>
            {loading && <p>Cargando...</p>}
            {error && <p>Error: {error}</p>}
            {data && <pre>{JSON.stringify(data, null, 2)}</pre>} {/* Mostrar resultados */}
        </div>
    );
};

export default ConsultaComponent;

// import React, { useState, useEffect } from 'react';

// function App() {
//   const [data, setData] = useState(null);

//   useEffect(() => {
//     // Llamada a la API (archivo PHP que consulta a la BD)
//     fetch('https://www.je-innovate.com/backend/getData.php')
//       .then(response => response.json())
//       .then(result => {
//         setData(result);
//       })
//       .catch(error => console.log('Error:', error));
//   }, []);

//   return (
//     <div>
//       <h1>Datos desde la base de datos</h1>
//       {data ? (
//         <pre>{JSON.stringify(data, null, 2)}</pre>
//       ) : (
//         <p>Cargando datos...</p>
//       )}
//     </div>
//   );
// }

// export default App;

// import logo from './logo.svg';
// import './App.css';
// import Card, { CardBody } from './components/Card';
// import List from './components/List';


// function App() {
//   var lista = [
//     { Id: 1, Nombre: 'Goku', Color:'bg-warning' },
//     { Id: 2, Nombre: 'Vegeta', Color:'bg-primary' },
//     { Id: 3, Nombre: 'Gohan', Color:'bg-danger' },
//     { Id: 4, Nombre: 'Cell', Color:'bg-success' }
//   ];
//   return (
//     <Card>
//       <CardBody title="HELLO WORLD!" text="FROM CHILDREN'S CARD"/>
//       <List lista={lista}/>
//     </Card>
//   );
// }

// export default App;
